// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyAE5mlT9py2vGI20sUOA0DNO7-qmbpQ1wI",
  authDomain: "fyre--atg.firebaseapp.com",
  projectId: "fyre--atg",
  storageBucket: "fyre--atg.appspot.com",
  messagingSenderId: "406544849860",
  appId: "1:406544849860:web:de687e0c30f76f22a52291",
  measurementId: "G-MH84YQFBJN"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
