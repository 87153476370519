import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>22 Duke Street, Darlington,<br />DL3 7AA</p>

            <a href="tel:+441325 380730" class="phone-link">01325 380 730</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Mon: 5pm – 10pm<br />
                Tues: Closed< br />
                Wed-Thurs: 5pm –10pm< br />
                Fri-Sat: 4pm –10pm<br />
                Sun: 4pm-9pm
              </p>
          </div>
          
          <div class="col-md-8">
            <iframe
            class="map"
            title="Abu's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18676.919891284335!2d-0.7560160568495358!3d54.18689421359083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e9bc412c50bbf%3A0x10f3608fa6105c2b!2zQWJ14oCZcw!5e0!3m2!1sen!2suk!4v1715089898849!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;